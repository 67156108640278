<template>
  <div
    style="
      background-color: white;
      border-bottom: 1px solid #d8dbe0;
      display: none;
    "
    class="version-desktop"
  >
    <div>
      <div class="desktop" style="border-bottom: 1px solid red">
        <b-row style="margin: 0 100px">
          <b-col class="col-4">
            <div class="header-logo" v-on:click="goToStore()">
              <router-link
                :to="{ name: $route.params.shopId ? 'store' : 'store domain' }"
                ><div class="img-logo-header">
                  <img
                    v-if="logoShop"
                    :src="logoShop"
                    width="100%"
                    height="100%"
                    style="
                      object-fit: contain;
                      width: 100%;
                      height: 100%;
                      object-position: left;
                    "
                  /><img
                    v-else
                    src="@/assets/img/cdea_tree.png"
                    width="100%"
                    height="100%"
                    style="
                      object-fit: contain;
                      width: 100%;
                      height: 100%;
                      object-position: left;
                    "
                  />
                </div>
              </router-link>
            </div>
          </b-col>
          <b-col cols="7" class="version-desktop">
            <div class="header-listOption-menu">
              <VueSlickCarousel
                v-bind="settingsSlick"
                v-if="dataMenu1.length > 0"
              >
                <template #nextArrow="arrowOption">
                  <div class="custom-arrow">
                    {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                  </div>
                </template>
                <template #prevArrow="arrowOption">
                  <div class="custom-arrow">
                    {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                  </div>
                </template>
                <div
                  class="header-listOption-menu-item link-header position-relative"
                  v-for="(item, index) in dataMenu1"
                  :key="index"
                >
                  <router-link
                    :to="`${beginUrl}${item.url}`"
                    class="d-flex flex-column align-items-center mt-2"
                    :class="{ 'img-menu-header': isImage(item.icon) }"
                  >
                    <img v-if="isImage(item.icon)" :src="item.icon" alt="" />
                    <b-icon v-else :icon="`${item.icon}`" />
                    <!-- <font-awesome-icon :icon="item.icon" /> -->
                    <span class="text-name">{{ item.page_title }}</span>
                    <span
                      v-if="item.page_title == 'カート' && is_login === true"
                      class="header-listOption-menu-item-cart position-absolute"
                    >
                      {{ countListCart }}
                    </span>
                    <span
                      v-if="
                        item.page_title == 'ニュース' &&
                        is_login === true &&
                        countNewUnread > 0
                      "
                      class="header-listOption-menu-news position-absolute"
                    >
                    </span>
                  </router-link></div
              ></VueSlickCarousel>
            </div>
          </b-col>
          <b-col class="col-1 version-desktop">
            <img
              @click="toggleSidebar"
              class="header-listOption-bar btnTabs"
              :src="`${icon_menu}`"
              alt=""
            />
          </b-col>
        </b-row>
        <b-row style="background: #ced2d8; padding: 0 15px">
          <b-col class="col-6 version-desktop" style="padding-left: 100px">
            <div
              class="header-search d-flex align-items-center"
              style="padding-left: 70px"
            >
              <b-form-input
                class="search-input"
                placeholder="商品名・目次で検索・著者名・カテゴリ名"
                v-model="keyword"
                v-on:keyup.enter="searchContent"
                style="width: 80%"
              />
              <b-icon
                class="search-icon"
                icon="search"
                style="right: 20%"
                @click="searchContent()"
              />
            </div>
          </b-col>
          <b-col class="col-6 version-desktop">
            <div class="header-tab d-flex align-items-end">
              <router-link :to="{ name: 'library' }">
                <div
                  class="custom-tab d-flex align-items-center justify-content-center"
                  :class="{ active: isActiveTabLibrary }"
                  @click="toggleActiveTabLibrary"
                  style="height: 50px"
                >
                  コンテンツ
                </div>
              </router-link>
              <router-link
                :to="{ name: $route.params.shopId ? 'store' : 'store domain' }"
              >
                <div
                  class="custom-tab d-flex align-items-center justify-content-center"
                  :class="{ active: isActiveTabStore }"
                  @click="toggleActiveTabStore"
                  style="height: 50px"
                >
                  ショップ
                </div>
              </router-link>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="mobile">
        <b-row>
          <b-col class="col-4">
            <div class="header-logo" v-on:click="goToStore()">
              <router-link
                :to="{ name: $route.params.shopId ? 'store' : 'store domain' }"
                ><div class="img-logo-header">
                  <img
                    v-if="logoShop"
                    :src="logoShop"
                    width="100%"
                    height="100%"
                    style="
                      object-fit: contain;
                      width: 100%;
                      height: 100%;
                      object-position: left;
                    "
                  /><img
                    v-else
                    src="@/assets/img/logo_default.png"
                    width="100%"
                    height="100%"
                    style="
                      object-fit: contain;
                      width: 100%;
                      height: 100%;
                      object-position: left;
                    "
                  />
                </div>
              </router-link>
            </div>
          </b-col>
          <b-col class="col-6 version-desktop">
            <div class="header-tab d-flex align-items-end">
              <router-link :to="{ name: 'library' }">
                <div
                  class="custom-tab d-flex align-items-center justify-content-center"
                  :class="{ active: isActiveTabLibrary }"
                  @click="toggleActiveTabLibrary"
                >
                  コンテンツ
                </div>
              </router-link>
              <router-link
                :to="{ name: $route.params.shopId ? 'store' : 'store domain' }"
              >
                <div
                  class="custom-tab d-flex align-items-center justify-content-center"
                  :class="{ active: isActiveTabStore }"
                  @click="toggleActiveTabStore"
                >
                  ショップ
                </div>
              </router-link>
            </div>
          </b-col>
          <b-col class="col-2 version-desktop">
            <img
              @click="toggleSidebar"
              class="header-listOption-bar btnTabs"
              :src="`${icon_menu}`"
              alt=""
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-12 version-desktop">
            <div class="header-search d-flex align-items-center">
              <b-form-input
                class="search-input"
                placeholder="商品名・目次で検索・著者名・カテゴリ名"
                v-model="keyword"
                v-on:keyup.enter="searchContent"
              />
              <b-icon
                class="search-icon"
                icon="search"
                @click="searchContent()"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <b-row class="version-desktop">
        <b-col>
          <div class="header-listOption d-flex">
            <div class="sidebar" :class="{ active: isActive }">
              <div class="backdrop" @click="toggleSidebar" />
              <div class="sidebar-menu">
                <div class="close-sidebar-btn" @click="toggleSidebar">
                  <b-icon icon="x" font-scale="1.25" class="mr-2"></b-icon>
                  <div class="infor-user pl-2">
                    <h4>
                      {{ userProfile.last_name }}{{ userProfile.first_name }}
                    </h4>
                    <p>{{ userProfile.address }}</p>
                  </div>
                </div>
                <div ref="sidebarScroll" class="sidebar-scroll">
                  <div class="sidebar-items">
                    <div
                      class="sidebar-link"
                      v-for="(item, index) in dataMenu1"
                      :key="index"
                    >
                      <router-link :to="`${beginUrl}${item.url}`">
                        <div
                          class="sidebar-item position-relative pl-2"
                          v-on:click="toggleSidebar"
                          :class="{
                            'img-menu-header-sidebar': isImage(item.icon),
                          }"
                        >
                          <img
                            v-if="isImage(item.icon)"
                            :src="item.icon"
                            alt=""
                          />
                          <b-icon v-else :icon="item.icon" font-scale="1.5" />
                          <!-- <font-awesome-icon :icon="item.icon" /> -->
                          <span class="ml-4">{{ item.page_title }}</span>
                          <span
                            v-if="
                              item.page_title == 'カート' && is_login === true
                            "
                            class="sidebar-menu-item-cart position-absolute"
                          >
                            {{ countListCart }}
                          </span>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
                <div class="menu-sidebar-logout flex-column p-3">
                  <!-- <a
                    v-if="is_login == true"
                    class="logout-btn border-0"
                    style="background: none"
                    :class="{ disabled: isLoading }"
                    @click.once="logoutBtn()"
                  >
                    <img :src="`${icon_logout}`" />
                  </a> -->
                  <CButton
                    v-on:click="logoutBtn()"
                    style="
                      background-color: #ef8a63cc;
                      color: white;
                      font-size: 0.8571em;
                    "
                    :size="'lg'"
                    class=""
                  >
                    ログアウト
                  </CButton>
                </div>
              </div>
            </div>
          </div>
          <!-- mobile -->
          <div class="header-listOption-menu-sp">
            <VueSlickCarousel
              v-bind="settingsSlickSP"
              v-if="dataMenu1.length > 0"
            >
              <template #nextArrow="arrowOption">
                <div class="custom-arrow">
                  {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                </div>
              </template>
              <template #prevArrow="arrowOption">
                <div class="custom-arrow">
                  {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                </div>
              </template>
              <div
                class="header-listOption-menu-sp-item position-relative link-header"
                v-for="(item, index) in dataMenu1"
                :key="index"
              >
                <router-link
                  :to="`${beginUrl}${item.url}`"
                  class="d-flex flex-column align-items-center"
                >
                  <b-icon :icon="item.icon" />
                  <span class="text-name">{{ item.page_title }}</span>
                  <span
                    v-if="item.page_title == 'カート' && is_login === true"
                    class="header-listOption-menu-item-cart position-absolute"
                  >
                    {{ countListCart }}
                  </span>
                </router-link>
              </div></VueSlickCarousel
            >
          </div>
        </b-col>
      </b-row>
      <div v-if="isShowModal === true && is_login === true">
        <PopUp @show_modal="hideModalPop"></PopUp>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
import { Api } from "../../utils/http-common";
import { Urls } from "../../utils/urls";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import PopUp from "@/views/default/PopUp";
import { Routes } from "../../utils/routes";
import icon_menu from "@/assets/img/iconmenu.png";
import icon_logout from "@/assets/img/menu_logout.png";

export default {
  name: "Header",
  data() {
    return {
      icon_menu: icon_menu,
      icon_logout: icon_logout,
      settingsSlick: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
      },
      settingsSlickSP: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
      },
      dataMenu: [
        { id: 1, icon: "person-fill", name: "著者" },
        { id: 2, icon: "book", name: "Name 2" },
        { id: 3, icon: "cart", name: "カート" },
        { id: 4, icon: "person-fill", name: "Name 4" },
        { id: 5, icon: "book", name: "Name 5" },
      ],
      isActive: false,
      showModal: false,
      isActiveTabStore: false,
      isActiveTabLibrary: false,
      isLoading: false,
      dataMenu1: [],
      countListCart: 0,
      isShowModal: true,
      beginUrl: this.$route.params.shopId
        ? "/" + Routes.SHOP + "/" + this.$route.params.shopId
        : "",
      keyword: "",
      dataShop: [],
      logoShop: null,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      is_login:
        localStorage.getItem(Constants.TOKEN_USER) ||
        localStorage.getItem(Constants.TOKEN_ADMIN)
          ? true
          : false,
    };
  },
  components: { VueSlickCarousel, PopUp },
  async created() {
    if (this.is_login) {
      this.getListSettingShopUser({
        domain: Constants.DOMAIN,
        shop_id: this.$route.params.shopId || null,
      });
      let bdRequest = {
        domain: Constants.DOMAIN,
        shop_id: this.$route.params.shopId || null,
      };
      let response = await this.$store.dispatch("getShopById", bdRequest);
      this.shop_id = response.data.id;
      const shop_id = this.shop_id;
      this.getListCart({
        shop_id,
      });
      this.checkUrl();
      this.getListSettingPageHeader(this.shop_id);
      if (localStorage.getItem(Constants.TOKEN_USER)) {
        const req = {
          shop_id: this.shop_id,
          user_id: localStorage.getItem(Constants.USER_ID),
        };
        this.getListSlagUser(req);
      }
      const formData = {
        shop_id: this.shop_id,
        user_type: localStorage.getItem(Constants.USER_TYPE_USER),
      };
      this.getProfile(formData);
      const request = {
        shop_id: this.shop_id,
      };
      this.getUnreadNew(request);
    }
  },
  computed: {
    ...mapGetters([
      "listCart",
      "success",
      "error",
      "amountCart",
      "listSettingPageHeader",
      "ListSettingShop",
      "listSlagUser",
      "userProfile",
      "countNewUnread",
    ]),
  },
  watch: {
    ListSettingShop() {
      this.dataShop = this.ListSettingShop;
      if (this.dataShop.length > 0) {
        if (this.dataShop[0].logo != null) {
          this.logoShop =
            process.env.VUE_APP_ROOT_BACKEND + "/" + this.dataShop[0].logo;
        }
      }
    },
    listSettingPageHeader() {
      this.dataMenu1 = this.listSettingPageHeader;
      if (this.dataMenu1.length > 5) {
        this.settingsSlick.arrows = true;
        this.settingsSlickSP.arrows = true;
      }
      if (window.matchMedia("only screen and (max-width: 767px)").matches) {
        this.settingsSlickSP.slidesToShow = 4;
      }
    },
    listCart() {
      this.countListCart = this.listCart.length;
      // this.listCart.forEach((element) => {
      //   element.list_cart.forEach(() => {
      //     this.countListCart += 1;
      //   });
      // });
    },
    listSlagUser() {
      const list = [];
      if (this.listSlagUser) {
        this.listSlagUser.forEach((item) => {
          list.push(item.id);
        });
        localStorage.setItem(Constants.LIST_SLAG, list);
      }
    },
  },
  methods: {
    ...mapActions({
      getListSettingShopUser: "getListSettingShopUser",
      getUnreadNew: "getUnreadNew",
      logout: "logout",
      getListCart: "getListCart",
      getListSettingPageHeader: "getListSettingPageHeader",
      getListSlagUser: "getListSlagUser",
      getProfile: "getProfile",
    }),
    searchContent() {
      if (this.keyword) {
        const self = this;
        const formData = {
          keyword: this.keyword,
          author_id: "",
          category_id: "",
          shop_id: this.shop_id,
          is_search_favorite: "",
          is_search_recent_content: "",
          is_search_new_content: "",
          limit: "8",
        };
        const api = localStorage.getItem(Constants.TOKEN_USER)
          ? Api.userRequestServer
          : Api.adminRequestServer;
        api
          .post(`/${Urls.LIBRARY}/${Urls.FILTER}`, formData)
          .then((response) => {
            const { data } = response;
            const keyword = self.keyword;
            if (data.success) {
              self.$router
                .push({
                  name: "library search",
                  params: {
                    keyword: self.keyword,
                  },
                })
                .catch(() => {
                  self.$router
                    .push({
                      name: "store",
                    })
                    .then(() => {
                      self.$router.push({
                        name: "library search",
                        params: {
                          keyword: keyword,
                        },
                      });
                    });
                });
              self.keyword = "";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    toggleNav() {
      this.isActive = !this.isActive;
      this.$emit("toggleNav", this.isActive);
    },
    showModalMenu() {
      this.showModal = !this.showModal;
    },
    logoutBtn() {
      this.isLoading = true;
      const shopId = this.shop_id;
      const api = localStorage.getItem(Constants.TOKEN_USER)
        ? Api.userRequestServer
        : Api.adminRequestServer;
      api
        .post(Urls.LOGOUT)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            this.$toasted.success(data.message).goAway(5000);
            this.$store.commit("set", ["success", false]);
            this.$store.commit("set", ["message", ""]);
            if (localStorage.getItem(Constants.TOKEN_USER)) {
              localStorage.removeItem(Constants.USER_TYPE_USER);
              localStorage.removeItem(Constants.TOKEN_USER);
              localStorage.removeItem(Constants.NORMAL_USER_INFO);
              localStorage.removeItem(Constants.LIST_SLAG);
            } else {
              localStorage.removeItem(Constants.USER_TYPE_ADMIN);
              localStorage.removeItem(Constants.TOKEN_ADMIN);
              localStorage.removeItem(Constants.ADMIN_USER_INFO);
            }
            if (this.$route.params.shopId) {
              this.$router.push({
                name: "login shop",
                params: { shopId: shopId },
              });
            } else {
              this.$router.push({
                name: "login shop domain",
              });
            }
            this.isLoading = false;
          } else {
            this.$toasted.error(data.message);
            this.$store.commit("set", ["message", ""]);
            this.$store.commit("set", ["error", false]);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.$toasted.error(error.message);
          this.$store.commit("set", ["message", ""]);
          this.$store.commit("set", ["error", false]);
        });
    },
    toggleSidebar() {
      this.isActive = !this.isActive;
      this.$emit("toggleSidebar", this.isActive);
    },
    goToMyPage() {
      this.isActiveTabLibrary = false;
      this.isActiveTabStore = true;
      if (this.$route.params.shopId) {
        this.$router.push({
          name: "my profile",
          params: { shopId: this.shop_id },
        });
      } else {
        this.$router.push({
          name: "my profile domain",
        });
      }
    },
    goToNews() {
      this.isActiveTabLibrary = false;
      this.isActiveTabStore = true;
      if (this.$route.params.shopId) {
        this.$router.push({
          name: "list new user",
          params: { shopId: this.shop_id },
        });
      } else {
        this.$router.push({
          name: "list new user domain",
        });
      }
    },
    goToListCart() {
      // const { params } = this.$route;
      // const { shopId } = params;
      this.isActiveTabLibrary = false;
      this.isActiveTabStore = true;
      if (this.$route.params.shopId) {
        this.$router.push({
          name: "default cart",
          params: { shopId: this.shop_id },
        });
      } else {
        this.$router.push({
          name: "default cart domain",
        });
      }
    },
    goToStore() {
      this.isActiveTabLibrary = false;
      this.isActiveTabStore = true;
      this.$router.push({
        name: this.$route.params.shopId ? "store" : "store domain",
      });
    },
    goToLibrary() {
      this.isActiveTabLibrary = true;
      this.isActiveTabStore = false;
      this.$router.push({
        name: this.$route.params.shopId ? "library" : "library domain",
      });
    },
    toggleActiveTabStore() {
      this.isActiveTabLibrary = false;
      this.isActiveTabStore = true;
      this.$emit("active", this.isActiveTabStore);
    },
    toggleActiveTabLibrary() {
      this.isActiveTabLibrary = true;
      this.isActiveTabStore = false;
      this.$emit("active", this.isActiveTabStore);
    },
    goToListAuthor() {
      const { params } = this.$route;
      const { shopId } = params;
      this.isActiveTabLibrary = false;
      this.isActiveTabStore = true;
      if (this.$route.params.shopId) {
        this.$router.push({
          name: "ListAuthorStore",
          params: { shopId: shopId },
        });
      } else {
        this.$router.push({
          name: "ListAuthorStore domain",
        });
      }
    },
    checkUrl() {
      const urlPage = window.location.pathname;
      const urlLibrary = urlPage.search("library");
      const urlStore = urlPage.search("store");
      if (urlLibrary !== -1) {
        this.isActiveTabLibrary = true;
        this.isActiveTabStore = false;
      } else if (urlStore !== -1) {
        this.isActiveTabStore = true;
        this.isActiveTabLibrary = false;
      } else if (urlLibrary === -1 && urlStore === -1) {
        this.isActiveTabStore = false;
        this.isActiveTabLibrary = true;
      }
    },
    hideModalPop(e) {
      this.isShowModal = e;
    },
    isImage(url) {
      return /\.(jpg|jpeg|png|JPG|JPEG|PNG)$/.test(url);
    },
  },
};
</script>
<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.img-logo-header {
  width: 150px;
  height: 79px;
  @media (max-width: 767px) {
    width: 100px;
  }
}
.sidebar {
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateX(100%);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1031;
  }
  &-menu {
    position: fixed;
    transform: translateX(100%);
    top: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);
    transition: all 0.5s;
    z-index: 1031;
    display: flex;
    flex-flow: column;
    flex-shrink: 1;
    width: 380px;
    .close-sidebar-btn {
      font-size: 28px;
      background-color: rgba(0, 0, 0, 0.2);
      padding: 20px 40px 0px 40px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      svg {
        cursor: pointer;
        color: #fff;
        position: absolute;
        top: 10px;
        right: 0;
      }
      .infor-user {
        font-size: 14px;
        color: white;
      }
    }
  }

  .menu-sidebar-logout {
    width: 100%;
    // height: 100px;
    // border-top: 1px solid #0176d6;
    display: flex;
    justify-content: center;
    align-items: center;
    .logout-btn {
      background-color: rgba(0, 0, 0, 0);
      padding: 10px;
      font-size: 15px;
      // cursor: pointer;
      opacity: 1;
      // border-radius: 10px;
      color: #000;
      border: none;
      &:hover {
        // background-color: rgba(0, 0, 0, 0.2);
        opacity: 0.7;
        cursor: pointer;
      }
      img {
        width: 100px;
      }
    }
  }

  &-scroll {
    // padding-right: 40px;
    padding: 20px 40px 0px 40px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #f1f1f1;
      border-radius: 6px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #c1c1c1;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #c1c1c1;
    }
    &::-webkit-scrollbar {
      width: 10px;
    }
  }
  @media (max-width: 767px) {
    &-scroll {
      margin: 20% 0px 0px;
      padding: 0;
    }
  }
  &-items {
    width: 330px;
    padding: 15px 20px 15px 0px;
    &:not(:first-child) {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .sidebar-link {
      text-decoration: none;
      cursor: pointer;
      a {
        &:hover {
          text-decoration: none;
        }
      }
      .sidebar-item {
        padding: 15px 20px 15px 0px;
        font-size: 15px;
        border-radius: 10px;
        color: #fff;
        &:hover {
          background-color: rgba(0, 0, 0, 0.2);
        }
        svg {
          font-size: 20px;
          // opacity: 35% !important;
        }
        .sidebar-menu-item-cart {
          height: 20px;
          width: 20px;
          background: orangered;
          font-size: 12px;
          border-radius: 50%;
          text-align: center;
          top: 10%;
          left: 5%;
          // opacity: 35%;
        }
      }
    }
  }
  &.active {
    .sidebar-menu {
      transform: translateX(0%);
    }
    .backdrop {
      transform: translateX(0%);
    }
  }
  @media (max-width: 767px) {
    .sidebar {
      &-menu {
        padding: 60px 20px 0 40px;
        width: 380px;
        &-scroll {
          padding-right: 10px;
          &-items {
            width: 270px;
            font-size: 16px;
            a {
              &-item {
                font-size: 16px;
                padding: 15px;
              }
            }
          }
        }
        .close-sidebar-btn {
          font-size: 28px;
          color: #fff;
          cursor: pointer;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
  }
}
@media (max-width: 992px) and (min-width: 768px) {
  .img-logo-header {
    width: 100%;
    height: 69px;
  }
}
.slick-next {
  right: 0;
  z-index: 1;
}
.slick-prev {
  left: 0;
  z-index: 1;
}
.slick-next::before {
  color: black !important;
}
.slick-prev::before {
  color: black !important;
}
.img-menu-header {
  width: 100%;
  height: 1em;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.img-menu-header-sidebar {
  display: flex;
  width: 100%;
  height: 3em;
  img {
    width: 8%;
    height: 100%;
    object-fit: contain;
  }
}
.btnTabs {
  width: 50px;
  height: 50px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  @media (max-width: 767px) {
    width: 35px;
    height: 35px;
  }
}
// .custom-tab {
//   margin-bottom: 10px;
//   position: relative;
// }
// .custom-tab::after {
//   content: "";
//   position: absolute;
//   width: 100%;
//   transform: scaleX(0);
//   height: 2px;
//   bottom: 0;
//   left: 0;
//   background-color: #f55747;
// }
// .custom-tab:hover:after {
//   transform: scaleX(1);
//   transition: transform 0.25s ease-out;
// }
// .custom-tab.active::after {
//   transform: scaleX(1);
// }
</style>
